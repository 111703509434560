const cookieName = "qrwf"


export function getCookieValue() {
    // deleteCookie()
    let user = getCookie();
    if (user != "") {
        return getBaseDecodeValue(user)
    }
    return null
}

export function setCookieValue(code, logid) {
    deleteCookie()
    const basen = getBaseEncodeValue(code, logid)
    setCookie(basen)
}

function setCookie(cvalue) {
    const d = new Date();
    d.setTime(d.getTime() + (10 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cvalue + ";" + expires + ";path=/";
}

function getBaseEncodeValue(code, logid) {
    const site = code + "@" + logid
    return btoa(site)
}

function getBaseDecodeValue(code) {
    const [site, logid] = atob(code).split("@")
    return { site, logid }
}

function getCookie() {
    let name = cookieName + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export function deleteCookie() {
    document.cookie = cookieName + '=; Max-Age=-99999999;';
}

export function checkCookie() {
    let user = getCookie();
    if (user != "") {
        return true
    }
    return false
}

export function getTimeDifference(referenceTimeStr) {
    // Parse the reference time string into a datetime object
    const referenceTime = new Date(referenceTimeStr);
  
    // Get the current time
    const currentTime = new Date();
  
    // Calculate the time difference in milliseconds
    const timeDiffMs = currentTime - referenceTime;
  
    // Convert the difference to hours and minutes
    const hours = Math.floor(timeDiffMs / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiffMs % (1000 * 60 * 60)) / (1000 * 60));
  
    // Return the difference as an object
    return { hours, minutes };
  }