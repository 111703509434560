import { Alert, Space } from "antd"

export const ThankYou = ({title}) => {
    return (
        <Space direction="vertical" align="center">
            <Alert
                message="Thank You"
                description={ title }
                type="success"
                showIcon
                closable
            />
        </Space>
    )
}