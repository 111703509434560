import { Avatar, Button, Divider, Form, Input, InputNumber, Space } from "antd"
import { useEffect, useState } from "react";
import React, { useMemo, useRef } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import { BASE_URL } from "../constant";
import { PlusOutlined } from '@ant-design/icons';
function isString(text) {
    // Check if text is a number using isNaN
    return typeof text === 'string' && isNaN(Number(text));
}



const getCompanyList = async (name) => {
    const url = `${BASE_URL}/emp/company?` + new URLSearchParams({
        name: name,
        email: name,
        phone: name
    });
    return fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(e => e.json()).then(body => {
        const list = body.elements.map((user) => (user))
        return list
    });
}

const getEmployeeList = async (name,site) => {
    const url = `${BASE_URL}/emp/employees/${site}?` + new URLSearchParams({
        name: name
    });
    return fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(e => e.json()).then(body => {
        const list = body.elements.map((user) => (user))
        return list
    });
}
const { Option } = Select;
const debounceTimeout = 800

function DebounceSelect({ fetchOptions, value, onChange, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                setOptions([
                    ...newOptions,
                    {
                        id: value,
                        name: value,
                        isNew: true
                    }
                ]);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            filterOption={false}
            showSearch={true}
            onSearch={debounceFetcher}
            onChange={onChange}
            value={value}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            optionLabelProp="label"
        >
            {options.map(item => (
                <Option key={item.id} label={item.name}>
                    {item.isNew ? (<>
                        <Space direction="vertical" size="large"><div style={{ color: "blue", width: "100%" }}><PlusOutlined /> Select to add your name : <b>{item.name}</b></div></Space>
                    </>) : (
                        <Space direction="horizontal">
                            <Avatar src={item?.avatar?.url} alt={item?.name} />
                            <Space.Compact size="small" direction="vertical">
                                <div>Name : {item.name} | Address : {item.address}</div>
                                <div>Company : {item.company?.name}</div>
                            </Space.Compact>
                        </Space>
                    )}
                </Option>
            ))}
        </Select>
    );
}


function DebounceSelectComp({ fetchOptions, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            setOptions([]);
            setFetching(true);
            fetchOptions(value).then((newOptions) => {
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    return (
        <Select
            filterOption={false}
            showSearch={true}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}
            optionLabelProp="label"
        >
            {options.map(item => (
                <Option key={item.id} label={item.name}>
                    <div>{item.name} | {item.address}</div>
                </Option>
            ))}
        </Select>
    );
}

export const FormEmp = ({ setSubmit, postService, serviceCode }) => {
    const [value, setValue] = useState();
    const [company, setCompany] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const saveAction = (data) => {
        postService(serviceCode, data).then(dat => {
            setSubmit(dat)
        })
    }
    useEffect(() => {
        setCompany(isString(value))
    }, [value])
    return <div style={{ marginTop: 20 }}>
        <Form
            onFinish={(e) => {
                saveAction(e)
            }}
            style={{ maxWidth: 600 }}
        >
            <Form.Item name={'name'} label="Name" rules={[{ required: true }]}>
                <DebounceSelect
                    value={value}
                    onChange={(newValue) => {
                        setValue(newValue);
                    }}
                    placeholder="Select your name"
                    fetchOptions={(value)=>getEmployeeList(value,serviceCode)}
                    style={{
                        width: '100%'
                    }}
                />
            </Form.Item>
            {
                company && (
                    <Form.Item name={"company"} label="Company" rules={[{ required: true }]}>
                        <DebounceSelectComp
                            value={companyId}
                            placeholder="Select your company"
                            fetchOptions={getCompanyList}
                            onChange={(newValue) => {
                                setCompanyId(newValue);
                            }}
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>
                )
            }
            <Form.Item style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit" size="large">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    </div>
}