export const BASE_URL = window.location.hostname === "localhost" ? "http://localhost:2065" : "https://api-qrwf.rewasoft.net";

// export const BASE_URL = "https://api-qrwf.rewasoft.net";

export const RatingType = {
    Very_Poor: "Very Poor",
    Poor: "Poor",
    Average: "Average",
    Good: "Good",
    Excellent: "Excellent"
}