import { Button, Flex, Space, Spin, Typography } from "antd";
import { Header } from "./components/Header";
import { useEffect, useState } from "react";
import { BASE_URL } from "./constant";
import { FormEmp } from "./components/Form";
import { NoTask } from "./components/NoTask";
import { TaskList } from "./components/TaskList";
import { ThankYou } from "./components/ThankYou";
import { deleteCookie, getCookieValue, getTimeDifference, setCookieValue } from "./utils"

const getServerData = async (code) => {
  return await fetch(`${BASE_URL}/emp/service/${code}`)
    .then(e => e.json())
    .then(e => {
      const ckValue = getCookieValue()
      if (ckValue !== null) {
        const logs = e.lastLog.find(e => e.logid == ckValue.logid)
        if (logs !== undefined) {
          return { ...e, isNew: false, logData: { logid: logs.logid, employee_name: logs.employee_name, start_time: logs.start_time, currentLog: getTimeDifference(logs.start_time) } }
        }
      }
      return { ...e, isNew: true, logData: null }
    }).then(e => {
      console.log(e)
      return e
    }).catch(e => {
      console.log(e)
    });
}

const postService = async (code, data) => {
  const response = await fetch(`${BASE_URL}/emp/task/${code}/submit`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  return await response.json().then(e => {
    setCookieValue(code, e.data.id)
    return { ...e, type: "started" }
  });
}

const completedService = async (taskid) => {
  const response = await fetch(`${BASE_URL}/emp/task/${taskid}/completed`,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  return await response.json().then(e => {
    deleteCookie()
    return { ...e, type: "completed" }
  })
}


function App() {
  const url = new URL(window.location.href); // Get current URL
  const serviceCode = url.searchParams.get('site');
  const [service, setService] = useState(null);
  const [submitted, setSubmitted] = useState(null);

  useEffect(() => {
    getServerData(serviceCode).then((data) => {
      setService(data)
    })
  }, [])

  if (!service) {
    return <Flex gap="middle" vertical justify="center" align="center" style={containerStyles}>
      <Flex vertical style={sectionStyles}>
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      </Flex>
    </Flex>
  }
  console.log(service)
  return (
    <Flex gap="middle" vertical justify="center" align="center" style={containerStyles}>
      <Flex vertical style={sectionStyles}>
        <Header title={service?.site_name ?? "Loading..."} lastTime={service?.lastTime ?? "09:16PM on 26 February"} />
        {service.isNew && submitted == null && (
          <>
            {/* {service.task_list?.length > 0 ? (
              <>
                <TaskList taskList={service.task_list} logTime={service.logTime} />
              </>
            ) : (
              <NoTask title={"Sorry, no task available"} />
            )} */}
            <FormEmp setSubmit={setSubmitted} postService={postService} serviceCode={serviceCode} />
          </>
        )}
        {!service.isNew && !submitted && (<Space direction="vertical" align="center">
          <Typography>Welcome back <b>{service.logData.employee_name} !!!</b></Typography>
          <Typography>Your current time log : <b>{service.logData.currentLog.hours > 0 && (service.logData.currentLog.hours + " hours and ")}{service.logData.currentLog.minutes} minutes</b></Typography>
          <Typography>Click following button to mark your task as completed</Typography>
          <Button type="primary" size="large" onClick={() => {
            completedService(service.logData.logid).then(e => setSubmitted(e))
          }}>
            Completed Task
          </Button>
        </Space>)}
        {submitted && (
          <ThankYou title={submitted.message} />
        )}
      </Flex>
    </Flex>
  );
}

export default App;

const containerStyles = {
  padding: "20px 16px",
  backgroundColor: "#ffffff",
  minHeight: "calc(100dvh - 40px)",
}

const sectionStyles = {
  padding: 20,
  backgroundColor: "#f5f5f5",
  maxWidth: "768px",
  width: "calc(100% - 34px)",
  borderRadius: "10px",
  boxShadow: "0 0 15px rgba(0,0,0,0.1)",
  border: "1px solid #eee"
}